<template>
	<div class="orderCenter">
		<Header />
		<div class="orderCenter_content">
			<LeftSide />
      <router-view class="router"></router-view>
		</div>
	</div>
</template>
<script>
import Header from "./Header";
import LeftSide from "./leftSide";

export default {
		components: {
			Header,
			LeftSide,
		},
		data: function() {
			return {};
		},
	};
</script>
<style lang="scss" scoped>
	.orderCenter {
		width: 100%;
		background-color: #f5f8fa;
		//border:1px solid blue;
    padding-bottom: 30px;

		&_content {
			display: flex;
			align-items: flex-start;
			justify-content: center;
		}
	}
</style>
