<template>
  <div class="header">
    <div class="header_inner">
      <div>
        <i class="el-icon-s-home"></i>{{ $t("Home") }} >
        {{ $t("Management Center") }} > {{ $t("Personal Center") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-top: 1px solid #f5f8fa;
  color: #515a6e;
  font-size: 16px;
  &_inner {
    width: 1540px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
